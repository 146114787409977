import { makeStyles } from '@material-ui/core';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions from '../styles/dimensions';

const NotFoundPage = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title="404: Nicht Gefunden" />
			<section className={classes.section}>
				<h1 className={classes.header}>404: Nicht Gefunden</h1>
				<p className={classes.paragraph}>Sie haben gerade eine Route getroffen, die es nicht gibt</p>
			</section>
		</Layout>
	);
};

export default NotFoundPage;

const useClasses = makeStyles({
	section: {
		minHeight: 'calc(100vh - 5.25em - 6.25em)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		marginTop: 0,
	},
	paragraph: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		color: Colors.textGray,
		margin: 0,
	},
});
